import React, { useRef, useEffect, useState } from "react";
import SimplePeer from "simple-peer";

const LiveStream = () => {
  const [stream, setStream] = useState(null);
  const videoRef = useRef();
  const peer = useRef();

  useEffect(() => {
    // Access the user's webcam
    navigator.mediaDevices.getUserMedia({ video: true, audio: true })
      .then((stream) => {
        setStream(stream);
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
        }
        
        // Initialize a peer
        peer.current = new SimplePeer({
          initiator: true,
          stream: stream,
          trickle: false,
        });

        peer.current.on("signal", (data) => {
          console.log("SIGNAL", data);
          // Send this signal data to the remote peer (e.g., through a server)
        });

        peer.current.on("stream", (remoteStream) => {
          // This will receive the stream from the remote peer
          videoRef.current.srcObject = remoteStream;
        });
      })
      .catch((err) => console.error("Failed to get local stream", err));

    return () => {
      if (stream) {
        stream.getTracks().forEach(track => track.stop());
      }
    };
  }, [stream]);

  return (
    <div>
      <h2>Live Stream</h2>
      <video ref={videoRef} autoPlay style={{ width: "500px" }} />
    </div>
  );
};

export default LiveStream;
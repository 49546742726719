import React from 'react';
import img1 from '../images/history.jpg'
const VideoPlayer = ({ src, width, height }) => {
    return (
      <div className="video-player">
        <video width={width} height={height} controls poster={img1}>
          <source src={src} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    );
  };
  export default VideoPlayer
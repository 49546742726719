import React from 'react'
import MainContent from "../components/MainContent"
import Services from "../components/Services"
import ContactUs from "../components/ContactUs"
import Committee from "../components/Committee"
import MyGallery from "../components/MyGallery"
import LiveStream from './LiveStream'
import MyEvents from "./MyEvents"
import { Router, Routes, Route, BrowserRouter } from 'react-router-dom'

const MainRouter = () => {
  return (
    <div>
      
            <Routes>
            <Route path="/" element={<MainContent />} />
            <Route path="/contacts" element={<ContactUs />} />
            <Route path="/services" element={<Services />} />
            <Route path="/committee" element={<Committee />} />
            <Route path="/gallery" element={<MyGallery />} />
            <Route path="/events" element={<MyEvents />} />
            </Routes>
            </div>
  )
}

export default MainRouter

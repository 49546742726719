import React from 'react';
import Map from '../components/Map';
import './MyMap.css';

function MyMap() {
  return (
    <div className="App">
      <hr  style={{
    color: '#000000',
    backgroundColor: '#000000',
    height: .5,
    borderColor : '#000000' 
}}/>
      <h4 className=''>Manjeri in Google Map</h4>
      <Map />
      <p></p>

      <hr  style={{
    color: '#000000',
    backgroundColor: '#000000',
    height: .5,
    borderColor : '#000000' 
}}/>

    </div>
  );
}

export default MyMap;
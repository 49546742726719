import React from 'react'
import VideoPlayer from '../components/VideoPlayer';
import '../components/HomeVideo.css';
import manjeri from '../video/manjeri.mp4';

  const parentv='img1'
const HomeVideo = (message={parentv}) => {
  return (
    
    <div className="my-vid-app">
      <hr  style={{
    color: '#000000',
    backgroundColor: '#000000',
    height: .5,
    borderColor : '#000000' 
}}/>
    <h5 className='bg-primary' >OUR MANJERI</h5>
    <VideoPlayer className='div-video' 
      src={manjeri} 
      
      width="400" 
      height="225" 
    />
  </div>
  )
}

export default HomeVideo
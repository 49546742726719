import React from 'react'
import { Tab, Tabs } from "react-bootstrap";
import wayanad from '../images/wayanad.jpeg'
import onam from '../images/onam.jpg'
import onamold from '../images/onam2023.jpg'
import iftar2023 from '../images/iftar2023.jpg'
import '../components/MyEvents.css';


const Events = () => {
  return (
    <div className='div-class'>
    <div >

<Tabs className='div-tab'
      defaultActiveKey="home"
      id="uncontrolled-tab-example"
         >
      <Tab eventKey="home" title="COMING EVENTS">
        <h5 className='bg-primary'>ONAM FEST 2024</h5>
        <div>
<img className='img-eve-class' src={onam} width={400} height={225}/>
          </div>
      </Tab>
      <Tab eventKey="profile" title="PAST EVENTS">
      <h5 className='bg-success'>PAST EVENTS</h5>
      <div>
<img className='img-eve-class' src={iftar2023} width={400} height={225}/>
          </div>
          <div>
<img className='img-eve-class' src={onamold} width={400} height={225}/>
          </div>

      </Tab>
      <Tab eventKey="contact" title="CHARITY">
      <h5 className='bg-danger'>SUPPORT WAYANAD</h5>
        <div>
<img className='img-eve-class' src={wayanad} width={400} height={225}/>
          </div>

      </Tab>
    </Tabs>
    </div>
    </div>
  )
}

export default Events
import React from 'react'
import {Container, Nav, Navbar} from 'react-bootstrap';
import "../components/MyNavigator.css";
import { Link } from 'react-router-dom';
import logoimg from "../images/logonew.jpg";
const MyNavigator = () => {
  return (
    <div className='div-nav'>
      <Link as={Link} to="/">
        <img className='logo2' src={logoimg}/>
        </Link>
<Navbar expand="lg" className="bg-tertiary">
      <Container className='nav-cont bg-tertiary'>
      
       
        <Navbar.Brand href="#home"></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav id="m-color" className="me-auto bg-tertiary">
            <Nav.Link as={Link} to="/" className='navi-link' href=""><h5 id='m-text'>HOME</h5></Nav.Link>
            <Nav.Link as={Link} to="/services" className='navi-link' href="./services"><h5 id='m-text'>SERVICES</h5></Nav.Link>
            <Nav.Link as={Link} to="/gallery" className='navi-link' href="#link"><h5 id='m-text'>GALLERY</h5></Nav.Link>
            <Nav.Link as={Link} to="/events" className='navi-link' href="#home"><h5 id='m-text'>EVENTS</h5></Nav.Link>
            <Nav.Link as={Link} to="/committee" className='navi-link' href="#link"><h5 id='m-text'>TEAM-MWA</h5></Nav.Link>
            <Nav.Link as={Link} to="/contacts" className='navi-link' href="#link"><h5 id='m-text'>CONTACT</h5></Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>


    </div>
  )
}

export default MyNavigator